<template>
  <v-row wrap no-gutters>
    <v-col cols="12" class="pt-1" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-switch-query`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.query"
        :label="$lang.labels.query"
        step-label="switch-query"
        required
        :readonly="!canEdit"
        @change="handleChange('query', $event)"
        @openEditor="$emit('openEditor', $event, 'query',
                           { text: '', value: properties.query, parentType: 'query' }
        )"
      />

      <v-btn
        class="button-help"
        style="position: absolute; top: 18px; right: 42px; background-color: var(--v-customBg-base);"
        text
        small
        color="info"
        :disabled="!canEdit"
        @click="handleChange('query', 'else')"
      >
        else
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-switch-name`"
        v-model="properties.name"
        outlined
        dense
        :label="$lang.labels.optionalQueryName"
        :readonly="!canEdit"
        data-cy="switch-name"
      />
    </v-col>
  </v-row>
</template>

<script>
import { TextInputWithEditorButton } from './components'

export default {
  name: 'QueryStep',
  components: {
    TextInputWithEditorButton
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      properties: { name: '', query: '' }
    }
  },
  watch: {
    properties: {
      handler(val) {
        this.$emit('changeStepProperties', { ...val })
      },
      deep: true
    },
    'step.name': {
      handler(val) {
        this.properties.name = val
      },
      deep: true
    },
    'step.query': {
      handler(val) {
        this.properties.query = val
      },
      deep: true
    }
  },
  created() {
    this.properties.name = this.step.name
    this.properties.query = this.step.query
  },
  methods: {
    handleChange(key, val) {
      this.properties[key] = val
    }
  }
}
</script>
